import request from "@/utils/request";

/**
 * 创建角色【ok】
 * @param params
 * @returns {*}
 */
export function createRole(params) {
  return request({
    url: "/api/backend/setting/permission/createRole",
    method: "post",
    data: params
  });
}

/**
 * 编辑角色权限【ok】
 * @param params
 * @returns {*}
 */
export function updateRolePermission(params) {
  return request({
    url: "/api/backend/setting/permission/updateRolePermission",
    method: "post",
    data: params
  });
}
/**
 * 查询角色【ok】
 * @param params
 * @returns {*}
 */
export function getRoleList(params) {
  return request({
    url: "/api/backend/setting/permission/getRoleList",
    method: "get",
    params
  });
}

/**
 * 查询级联角色【ok】
 * @param params
 * @returns {*}
 */
export function getCascadeRole(params) {
  return request({
    url: "/api/backend/setting/permission/getCascadeRole",
    method: "get",
    params
  });
}

/**
 * 查询角色权限数【ok】
 * @param params
 */
export function getRolePermissionTree(params) {
  return request({
    url: "/api/backend/setting/permission/getRolePermissionTree",
    method: "get",
    params
  });
}
