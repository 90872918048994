<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button
        class="filter-item"
        type="primary"
        @click="checkAddOrEditDialogVisible(true, {}, 'CREATE')"
        >新增</el-button
      >
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="name" label="角色名称" width="280">
      </el-table-column>
      <!--      <el-table-column prop="id" label="编号" width="80"> </el-table-column>-->
      <el-table-column prop="parent_id" label="父级编号"> </el-table-column>
      <el-table-column align="center" prop="created_at" label="创建时间">
        <template slot-scope="scope">
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="修改时间">
        <template slot-scope="scope">
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="330"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="checkAddOrEditDialogVisible(true, scope.row, 'CREATE')"
            >创建子角色</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="checkAddOrEditDialogVisible(true, scope.row, 'UPDATE')"
            >编辑子角色</el-button
          >
          <el-button
            type="warning"
            size="mini"
            @click="checkPermissionDialogVisible(true, scope.row)"
            >编辑权限</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogType]"
      :visible.sync="addOrEditDialogVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkAddOrEditDialogVisible(false, {}, '')"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="saveAddOrEditData"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!--    编辑权限数-->
    <el-dialog
      title="编辑权限树"
      :visible.sync="permissionDialogVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="permissionForm"
        label-position="left"
        label-width="250px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="选择权限" prop="name" class="permission_warp">
          <el-tree
            :data="permissionList"
            show-checkbox
            node-key="id"
            :default-expanded-keys="permissionForm.permission"
            :default-checked-keys="permissionForm.permission"
            :props="defaultProps"
            @check="permissionCheck"
          >
          </el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkPermissionDialogVisible(false, {})"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="savePermissionData"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRoleList,
  createRole,
  getRolePermissionTree,
  updateRolePermission
} from "@/api/role";
import { DIALOG_TITLE_TYPE } from "@/utils/enumUtile";
import { formatApiDate } from "@/utils/utils";
import { setMenusCheckKey, setMenus } from "@/utils/auth";
import { refreshPermMenu } from "@/api/login";

export default {
  computed: {
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    },
    formatApiDate() {
      return formatApiDate;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: [],
      listLoading: true,
      listQuery: {},
      addOrEditDialogVisible: false, // 新增 or 编辑的弹框
      dialogType: "",
      parentObj: {}, // 选中的父级的对象
      rules: {
        name: [{ required: true, message: "名称不能为空", trigger: "change" }]
      },
      form: {
        id: 0, // 默认为父级
        parentId: 0, // 默认为父级
        name: ""
      },
      btnLoading: false,
      permissionDialogVisible: false, // 权限树
      permissionForm: {
        id: "", // 角色id
        permission: []
      },
      permissionList: [],
      defaultProps: {
        children: "children",
        label: "name"
      }
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    /**
     * 查询用户列表
     */
    getList() {
      this.listLoading = true;
      getRoleList(this.listQuery)
        .then(response => {
          this.list = response.data || [];
          // this.total = response.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 编辑 or 添加
     * @param status
     * @param parentObj
     * @param type
     */
    checkAddOrEditDialogVisible(status, parentObj = {}, type) {
      this.addOrEditDialogVisible = status;
      this.dialogType = type;
      if (parentObj && Object.keys(parentObj).length !== 0) {
        this.form.id = type === "CREATE" ? 0 : parentObj.id;
        this.form.parentId = parentObj.id;
        this.form.name = type === "UPDATE" ? parentObj.name : "";
      } else {
        this.form.id = 0;
        this.form.parentId = 0;
        this.form.name = "";
      }
      if (!status) {
        this.form = {
          id: 0, // 当前得id
          parentId: 0, // 默认为父级
          name: ""
        };
      }
    },

    /**
     * 确认添加还是编辑
     */
    saveAddOrEditData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const params = this.form;
          this.btnLoading = true;
          createRole(params)
            .then(() => {
              this.checkAddOrEditDialogVisible(false, {}, "");
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
              this.getList();
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },

    /**
     * 编辑权限数的弹框状态
     * @param status 弹框的状态（true：显示，false:隐藏）
     * @param parentObj 编辑弹框的对象
     */
    checkPermissionDialogVisible(status, parentObj = {}) {
      if (status) {
        this.permissionForm.id = parentObj.id;
        this.getRolePermissionTree(parentObj.id);
      } else {
        this.permissionForm.id = "";
        this.permissionForm.permission = [];
        this.permissionList = [];
      }
      this.permissionDialogVisible = status;
    },
    /**
     * 根据角色id查询
     * @param roleId 角色id
     */
    getRolePermissionTree(roleId) {
      const params = {
        roleId
      };
      getRolePermissionTree(params).then(resp => {
        this.permissionList = resp.data.menu_tree;
        const permissionsArr = resp.data.permissions;
        const defaultPermission = permissionsArr.map(
          item => item.permission_id
        );
        this.permissionForm.permission = defaultPermission;
      });
    },

    /**
     * 权限改变时
     * @param data
     * @param checkItem 选中的key
     */
    permissionCheck(data, checkItem) {
      this.permissionForm.permission = checkItem.checkedKeys;
    },

    /**
     * 确认添加权限
     */
    savePermissionData() {
      const permissionForm = this.permissionForm;
      if (
        !permissionForm.id ||
        permissionForm.id === 0 ||
        permissionForm.permission.length === 0
      ) {
        this.$notify({
          title: "提示",
          message: "请至少勾选一个权限",
          type: "warning",
          duration: 2000
        });
        return;
      }
      this.btnLoading = true;
      const params = {
        ...permissionForm,
        permission: permissionForm.permission.join(",")
      };
      updateRolePermission(params)
        .then(() => {
          refreshPermMenu()
            .then(resp => {
              setMenus(resp.data);
              setMenusCheckKey(true);
              // 刷新当前页面
              location.reload();
            })
            .finally(() => {
              this.checkPermissionDialogVisible(false);
              this.$notify({
                title: "成功",
                message: "编辑成功",
                type: "success",
                duration: 2000
              });
            });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    }
  }
};
</script>
<style scoped lang="scss">
.permission_warp {
  /deep/.el-tree {
    width: 500px;
  }
}
</style>
